import {useRouter} from "next/router";
import {useTrackedSelector} from "@digitalstage/api-client";
import React from "react";

const useRequireLogin = (): null => {
    const {isReady, push} = useRouter()
    const state = useTrackedSelector()

    React.useEffect(() => {
        if (isReady && state.auth.initialized) {
            if (!state.auth.token) {
                // Not signed in
                console.info("Login required, forwarding you to /account/login")
                push("/account/login")
                    .catch(err => console.error("Could not forward to login page, reason:", err))
            }
        }
    }, [ isReady, push, state.auth.initialized, state.auth.token])

    return null
}
export {useRequireLogin}
