import type {NextPage} from 'next'
import {useRouter} from "next/router";
import {useTrackedSelector} from "@digitalstage/api-client";
import {useRequireLogin} from "../lib/useRequireLogin";
import {Loading} from "@digitalstage/ui/Loading";
import React from "react";

const Home: NextPage = () => {
    const {isReady, replace} = useRouter()
    const state = useTrackedSelector()
    useRequireLogin()

    React.useEffect(() => {
        if (isReady && state.globals.ready) {
            if (state.globals.stageId) {
                replace('/stage')
            } else {
                replace('/stages')
            }
        }
    }, [isReady, replace, state.globals.ready, state.globals.stageId])

    return <Loading/>
}

export default Home
